import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { Table, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const CrowdfundingDetailPage = () => {
    const campaign_id = window.location.pathname.split('/').pop();
    const [campaign, setCampaign] = useState(null);
    const [donations, setDonations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [verifying, setVerifying] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchCampaignDetailsAndDonations();
        console.log(campaign_id)
    }, [campaign_id]);

    // ✅ Fetch campaign details & donations together
    const fetchCampaignDetailsAndDonations = async () => {
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.post(
                '/crowdfunding/campaign/details', // ✅ Single API call
                { campaign_id: campaign_id },
                { headers }
            );
            console.log(campaign_id)
            if (response.data.response_code === 1000) {
                setCampaign(response.data.data.campaign);
                setDonations(response.data.data.donations);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Error fetching campaign details:', error);
            toast.error("Error fetching campaign details", { autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    };
    const verifyDonation = async (donation_uuid) => {
        setVerifying(true);
        const headers = {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.post(
                '/crowdfunding/verify',
                { donation_uuid },
                { headers }
            );

            if (response.data.response_code === 1000) {
                toast.success("Donation verified successfully!", { autoClose: 5000 });
                fetchCampaignDetailsAndDonations(); // Refresh donation list
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Error verifying donation:', error);
            toast.error("Error verifying donation", { autoClose: 5000 });
        } finally {
            setVerifying(false);
        }
    };

    return (
        <Fragment>
            <div className="container">
                <button className="btn btn-secondary mb-3" onClick={() => navigate(-1)}>
                    ← Back to Campaigns
                </button>

                {loading ? (
                    <p>Loading...</p>
                ) : campaign ? (
                    <div className="card">
                        <div className="card-header">
                            <h4>{campaign.title}</h4>
                        </div>
                        <div className="card-body">
                            <p><strong>Description:</strong> {campaign.description}</p>
                            <p><strong>Goal Amount:</strong> RM {campaign.goal_amount}</p>
                            <p><strong>Raised Amount:</strong> RM {campaign.raised_amount}</p>
                            <p><strong>Status:</strong> {campaign.status}</p>

                            {campaign.background_image_url && (
                                <img
                                    src={campaign.background_image_url}
                                    alt="Campaign"
                                    style={{ width: '100%', maxHeight: '300px', objectFit: 'cover', borderRadius: '10px' }}
                                />
                            )}

                            <hr />
                            <h5>Donations</h5>
                            {donations.length > 0 ? (
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Donor</th>
                                            <th>Amount (RM)</th>
                                            <th>Status</th>
                                            <th>Receipt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {donations.map((donation, index) => (
                                            <tr key={donation.donation_uuid}>
                                                <td>{index + 1}</td>
                                                <td>{donation.donor_name || 'Anonymous'}</td>
                                                <td>{donation.donation_amount}</td>
                                                <td>{donation.status === 1 ? 'Paid' : 'Pending'}</td>
                                                <td>
                                                    {donation.receipt_url ? (
                                                        <a href={donation.receipt_url} target="_blank" rel="noopener noreferrer">
                                                            View Receipt
                                                        </a>
                                                    ) : (
                                                        'No Receipt'
                                                    )}
                                                </td>
                                                <td>
                                                    {donation.status == 1 && (
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            onClick={() => verifyDonation(donation.donation_uuid)}
                                                            disabled={verifying}
                                                        >
                                                            {verifying ? "Verifying..." : "Approve"}
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No donations yet.</p>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Campaign not found.</p>
                )}
            </div>
        </Fragment>
    );
};

export default CrowdfundingDetailPage;
