import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CrowdfundingPage = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [updatedCampaignTitle, setUpdatedCampaignTitle] = useState('');
    const [updatedCampaignDescription, setUpdatedCampaignDescription] = useState('');

    const navigate = useNavigate();

    // Columns for DataTable
    const [columns] = useState([
        {
            name: "Campaign Title",
            selector: (row) => row.title,
            sortable: true,
            center: true,
        },
        {
            name: "Description",
            selector: (row) => row.description,
            center: true,
        },
        {
            name: "Goal Amount (RM)",
            selector: (row) => row.goal_amount,
            sortable: true,
            center: true,
        },
        {
            name: "Raised Amount (RM)",
            selector: (row) => row.raised_amount,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            center: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className=''>
                    <button
                        className="btn btn-dark"
                        style={{ marginRight: '10px' }}
                        onClick={() => navigate(`/crowdfunding-details/${row.campaign_id}`)}
                    >
                        View
                    </button>
                </div>
            ),
            center: true,
        }
    ]);
    const fetchCampaigns = () => {
        const headers = {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.post('/crowdfunding/campaigns', null, { headers })  // Set request body as null if empty
            .then((response) => {
                console.log(response)
                if (response.data.response_code === 1000) {
                    setCampaigns(response.data.data);
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {

                toast.error("Error fetching campaigns", { autoClose: 5000 });
            });
    };


    useEffect(() => {
        fetchCampaigns();
    }, []);



    const closeEditModal = () => {
        setSelectedCampaign(null);
        setIsEditModalOpen(false);
    };

    const closeDeleteModal = () => {
        setSelectedCampaign(null);
        setIsDeleteModalOpen(false);
    };


    const handleSaveChanges = () => {
        if (!updatedCampaignTitle || !updatedCampaignDescription) {
            toast.error("Title and description cannot be empty", { autoClose: 5000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.post('/crowdfunding/updateCampaign', {
            campaign_id: selectedCampaign.campaign_id,
            title: updatedCampaignTitle,
            description: updatedCampaignDescription
        }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    toast.success("Campaign updated successfully", { autoClose: 5000 });
                    closeEditModal();
                    fetchCampaigns(); // Refresh the campaigns list
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error updating campaign", { autoClose: 5000 });
            });
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25 text-red">
                                <h5>Crowdfunding Campaigns</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={campaigns}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedCampaign && (
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
                    <ModalHeader toggle={closeEditModal}>
                        Edit Campaign Details
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>Campaign Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={updatedCampaignTitle}
                                onChange={(e) => setUpdatedCampaignTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Campaign Description</label>
                            <textarea
                                className="form-control"
                                value={updatedCampaignDescription}
                                onChange={(e) => setUpdatedCampaignDescription(e.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            )}

            <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
                <ModalHeader toggle={closeDeleteModal}>
                    Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the campaign: <strong>{selectedCampaign?.title}</strong>?
                </ModalBody>
                <ModalFooter>

                    <Button color="secondary" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default CrowdfundingPage;
