import React from 'react';
import ReactDOM from 'react-dom/client'; // ✅ Use "react-dom/client"
import './index.scss';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import MainRoutes from './routes';

const Root = () => {
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    <MainRoutes />
                </BrowserRouter>
            </Provider>
            <div id="root-toastify"></div>
        </div>
    );
};

// ✅ Fix: Use ReactDOM.createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

// Service Worker
serviceWorker.unregister();
