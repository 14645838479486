import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import dayjs from 'dayjs'; // Import dayjs for date handling

const ClassProfile = () => {
    const classId = window.location.pathname.split('/').pop(); // Extract class ID from URL
    const [students, setStudents] = useState([]); // List of students
    const [className, setClassName]=useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Selected date on the calendar
    const [loading, setLoading] = useState(false);
    const [allClasses, setAllClasses] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [newClassId, setNewClassId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getClassName();
        fetchAllClasses();
    }, [classId]);
    
    const getClassName = async()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.post(
                `/school/classID`,
                { class_id: classId }, // Include class_id and date in the body
                { headers }
            );

            if (response.data.response_code === 1000) {
                setClassName(response.data.data.class_name || ''); // Update students data
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Error fetching class:', error);
            toast.error("Error fetching class name", { autoClose: 5000 });
        } finally {
           
        }

    }
    const fetchAllClasses = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.get(`/school/classes`, { headers });
            console.log(response)
            if (response.data.response_code === 1000) {
                setAllClasses(response.data.data || []);
            } else {
                toast.error("Failed to fetch class list", { autoClose: 5000 });
            }
        } catch (error) {
            console.error("Error fetching class list:", error);
            toast.error("Error fetching class list", { autoClose: 5000 });
        }
    };
    // Fetch students based on class and selected date
    const fetchStudentsAttendance = async (date) => {
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Format as YYYY-MM-DD

        try {
            const response = await API.post(
                `/attendance/attendanceByClass`,
                { class_id: classId, date: formattedDate }, // Include class_id and date in the body
                { headers }
            );

            if (response.data.response_code === 1000) {
                setStudents(response.data.data || []); // Update students data
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            toast.error("Error fetching students", { autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStudentsAttendance(selectedDate);
    }, [classId, selectedDate]);
    

    // Highlight dates with attendance records
    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            const record = students?.date_summary?.find(item => item.date === formattedDate);
            if (record && record.total_students > 0) {
                return (
                    <div>
                        {record.total_absent + record.total_present}/{record.total_students}
                    </div>
                );
            }
        }
        return null;
    };

    // Group and filter detailed records by student_uuid and selected date
    const formattedSelectedDate = dayjs(selectedDate).format('YYYY-MM-DD');
    const groupedRecords = students.detailed_records?.reduce((acc, record) => {
        const studentId = record.student_uuid;

        // Initialize the student record if not already present
        if (!acc[studentId]) {
            acc[studentId] = {
                profile_pic:record.profile_pic,
                student_status:record.student_status,
                student_uuid: record.student_uuid,
                first_name: record.first_name,
                attendance: [], // Store attendance details
            };
        }

        // Only include records matching the selected date
        if (record.date === formattedSelectedDate) {

            acc[studentId].attendance.push({
                profile_pic: record.profile_pic,
                student_status:record.student_status,
                date: record.date,
                status: record.status,
                check_in_time: record.check_in_time,
                check_in_gate: record.check_in_gate,
                check_out_time: record.check_out_time,
                check_out_gate: record.check_out_gate,
                check_in_image_url: record.check_in_image_url || null,
                check_out_image_url: record.check_out_image_url || null,
                letter_attachment_url: record.letter_attachment_url || null,
                remarks: record.remarks,
            });
        }

        return acc;
    }, {});

    const notifyParent = async (studentUuid) => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        try {
            const response = await API.post(
                `/attendance/notifyParent`,
                JSON.stringify({ student_id: studentUuid }), // Pass the student UUID and date
                { headers }
            );

            if (response.data.response_code === 1000) {
                toast.success("Parent notified successfully", { autoClose: 5000 });
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {

            toast.error("Error notifying parent", { autoClose: 5000 });
        }
    };
    const openUpdateClassModal = (student) => {
        setSelectedStudent(student);
        setModalOpen(true);
    };
    const closeModal = () => {
        setSelectedStudent(null);
        setNewClassId('');
        setModalOpen(false);
    };
    const updateStudentClass = async () => {
        if (!selectedStudent || !newClassId) {
            toast.error("Please select a new class.", { autoClose: 5000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        try {
            const response = await API.post(
                `/portal/modifyClass`,
                { student_id: selectedStudent.student_uuid, new_class_id: newClassId },
                { headers }
            );

            if (response.data.response_code === 1000) {
                toast.success("Student class updated successfully!", { autoClose: 5000 });
                fetchStudentsAttendance(selectedDate);
                closeModal();
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Error updating student class:', error);
            toast.error("Error updating student class", { autoClose: 5000 });
        }
    };
    const getStatusChip = (status) => {
        switch (status) {
            case '0':
                return (
                    <span 
                        style={{
                            backgroundColor: '#f8d7da',
                            color: '#721c24',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Unrecorded
                    </span>
                );
            case '3':
                return (
                    <span 
                        style={{
                            backgroundColor: '#fff3cd',
                            color: '#856404',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Requires Re-record
                    </span>
                );
            case '4':
                    return (
                        <span 
                            style={{
                                backgroundColor: '#fff3cd',
                                color: '#155724',
                                padding: '5px 10px',
                                borderRadius: '12px',
                                fontWeight: 'bold',
                            }}
                        >
                            Parents not linked
                        </span>
                    );
            case '2':
                return (
                    <span 
                        style={{
                            backgroundColor: '#d4edda',
                            color: '#155724',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Completed
                    </span>
                );
            default:
                return (
                    <span 
                        style={{
                            backgroundColor: '#f8f9fa',
                            color: '#6c757d',
                            padding: '5px 10px',
                            borderRadius: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Unknown
                    </span>
                );
        }
    };
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Class Attendance for {className}</h4>
                            </div>
                            <div className="card-body">
                                <Calendar
                                    onChange={(date) => setSelectedDate(date)} // Update selected date as string
                                    value={selectedDate} // Convert string to Date object
                                    tileContent={tileContent} // Highlight dates
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {loading ? (
                            <p>Loading students...</p>
                        ) : groupedRecords && Object.keys(groupedRecords).length > 0 ? (
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th></th>
                                        <th>Status</th>
                                        <th>Student Status</th>
                                        <th>Attendance Timeline</th>
                                        <th>Action</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(groupedRecords).map((student, index) => (
                                       
                                        <tr key={student.student_uuid}>
                                            <td>{index + 1}</td>
                                            <td>{student.first_name}
                                            </td>
                                            <td>
                                                    <img
                                                        src={student.profile_pic}  // Use the profile_pic URL here
                                                        alt={`${student.first_name}'s Profile`}
                                                        style={{
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50%',
                                                            marginLeft: '10px',  // Add some space between name and profile picture
                                                            objectFit: 'cover',  // Ensures the image fits within the 50x50 box
                                                        }}
                                                    />
                                                

                                            </td>
                                            <td>
                                            {getStatusChip(student.student_status)} {/* Display the chip */}
                                            </td>
                                            <td>
                                                {/* Render statuses for the student */}
                                                {student.attendance.map((att, i) => (
                                                    <div
                                                        key={i}
                                                        className="mb-2"
                                                        style={{
                                                            display: 'inline-block',
                                                            padding: '5px 10px',
                                                            borderRadius: '12px',
                                                            fontWeight: 'bold',
                                                            backgroundColor: att.status == '0'
                                                                ? '#f8d7da' // Light red for background (Absent)
                                                                : att.status == '1'
                                                                    ? '#d4edda' // Light green for background (Check-in)
                                                                    : att.status == '2'
                                                                        ? '#d1ecf1' // Light blue for background (Check-out)
                                                                        : '#f8f9fa', // Light gray for background (Unrecorded)
                                                            color: att.status == '0'
                                                                ? '#721c24' // Dark red for text (Absent)
                                                                : att.status == '1'
                                                                    ? '#155724' // Dark green for text (Check-in)
                                                                    : att.status == '2'
                                                                        ? '#0c5460' // Dark blue for text (Check-out)
                                                                        : '#6c757d', // Dark gray for text (Unrecorded)
                                                        }}
                                                    >
                                                        {att.status == '0'
                                                            ? 'Absent'
                                                            : att.status == '1'
                                                                ? 'Check-in'
                                                                : att.status == '2'
                                                                    ? 'Check-out'
                                                                    : 'Unrecorded'}
                                                    </div>

                                                ))}
                                            </td>
                                            <td>
                                                <div className="timeline" style={{ position: 'relative', paddingLeft: '20px', borderLeft: '2px solid #28A745' }}>
                                                    {student.attendance
                                                        .filter(att => att.status !== 'unrecorded') // Filter out unrecorded statuses
                                                        .map((att, i) => {
                                                            // Calculate duration
                                                            const duration =
                                                                att.check_in_time && att.check_out_time
                                                                    ? moment
                                                                        .duration(moment.unix(att.check_out_time).diff(moment.unix(att.check_in_time)))
                                                                        .humanize()
                                                                    : null;

                                                            return (
                                                                <div
                                                                    key={i}
                                                                    style={{
                                                                        position: 'relative',
                                                                        marginBottom: '20px',
                                                                        paddingLeft: '20px',
                                                                    }}
                                                                >
                                                                    {/* Timeline Marker */}
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            left: '-28px',
                                                                            top: '0',
                                                                            width: '15px',
                                                                            height: '15px',
                                                                            backgroundColor: '#28A745', // Red color
                                                                            borderRadius: '50%',
                                                                            border: '2px solid white',
                                                                            boxShadow: '0 0 0 2px #28A745',
                                                                        }}
                                                                    ></div>

                                                                    {/* Check-in Time */}
                                                                    {att.check_in_time && (
                                                                        <div style={{ color: '#28A745', fontWeight: 'bold', marginBottom: '5px' }}>
                                                                            Check-in: {moment.unix(att.check_in_time).format('hh:mm A')}
                                                                            <br /> Gate: {att.check_in_gate}
                                                                        </div>
                                                                    )}

                                                                    {/* Check-in Image */}
                                                                    {att.check_in_image_url && (
                                                                        <img
                                                                            src={att.check_in_image_url}
                                                                            alt="Check-In"
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                marginTop: '5px',
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #28A745',
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {/* Check-out Time */}
                                                                    {att.check_out_time && (
                                                                        <div style={{ color: '#28A745', fontWeight: 'bold', marginTop: '10px', marginBottom: '5px' }}>
                                                                            Check-out: {moment.unix(att.check_out_time).format('hh:mm A')}
                                                                            <br /> Gate: {att.check_out_gate}
                                                                        </div>
                                                                    )}

                                                                    {/* Check-out Image */}
                                                                    {att.check_out_image_url && (
                                                                        <img
                                                                            src={att.check_out_image_url}
                                                                            alt="Check-Out"
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                marginTop: '5px',
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #28A745',
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {/* Duration */}
                                                                    {duration && (
                                                                        <div style={{ color: '#28A745', marginTop: '10px', fontWeight: 'bold' }}>
                                                                            Duration: {duration}
                                                                        </div>
                                                                    )}
                                                                    {/* Remarks */}
                                                                    {att.remarks && (
                                                                        <div style={{ color: '#28A745', marginTop: '10px' }}>
                                                                            <strong>Remarks:</strong> {att.remarks}
                                                                        </div>
                                                                    )}
                                                                    {/* Button for Letter Attachment */}
                                                                    {att.status === '0' && att.letter_attachment_url && (
                                                                        <div style={{ marginTop: '10px' }}>
                                                                            <button
                                                                                style={{
                                                                                    backgroundColor: '#e74c3c',
                                                                                    color: 'white',
                                                                                    border: 'none',
                                                                                    borderRadius: '5px',
                                                                                    padding: '5px 10px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => window.open(att.letter_attachment_url, '_blank')}
                                                                            >
                                                                                View Letter
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </td>
                                            <td>
                                                {student.attendance.some(att => att.status == 'unrecorded') && (
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => notifyParent(student.student_uuid)}
                                                        style={{
                                                            backgroundColor: '#007bff',
                                                            color: '#fff',
                                                            fontSize: '12px',
                                                            padding: '5px 10px',
                                                            borderRadius: '4px',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Notify Parent
                                                    </button>
                                                )}
                                                {/* Add a "View Letter" button for status 0 */}
                                                {student.attendance
                                                    .filter(att => att.status == '0' && att.letter_attachment_url)
                                                    .map((att, i) => (
                                                        <button
                                                            key={`view-letter-${i}`}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => window.open(att.letter_attachment_url, '_blank')}
                                                            style={{
                                                                backgroundColor: '#e74c3c',
                                                                color: '#fff',
                                                                fontSize: '12px',
                                                                padding: '5px 10px',
                                                                borderRadius: '4px',
                                                                border: 'none',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            View Letter
                                                        </button>
                                                    ))}
                                            </td>
                                            <td>
                                                {/* Change Class Button */}
                                                <button
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => openUpdateClassModal(student)}
                                                >
                                                    Change Class
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        ) : (
                            <p>No students found for the selected date.</p>
                        )}
                    </div>
                </div>
                 {/* Modal for Updating Class */}
            <Modal isOpen={modalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Update Student's Class</ModalHeader>
                <ModalBody>
                    <select
                        className="form-control"
                        value={newClassId}
                        onChange={(e) => setNewClassId(e.target.value)}
                    >
                        <option value="">Select a new class</option>
                        {allClasses.map((cls) => (
                            <option key={cls.class_id} value={cls.class_id}>
                                {cls.class_name}
                            </option>
                        ))}
                    </select>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={updateStudentClass}>Update</Button>
                    <Button color="secondary" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </div>
        </Fragment>
    );
};

export default ClassProfile;
