import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const full_name=localStorage.getItem('full_name')
    const school_name = localStorage.getItem('school_name')
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    {/* <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" /> */}
                    {/* <div className="profile-edit">
                        <Link to={`/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <h6 className="mt-3 f-14">{full_name}</h6>
                <p>{school_name}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;