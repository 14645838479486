import React from 'react';

// Import custom Components 
import Homepage from '../components/dashboard/home';
import ClassesPage from '../components/dashboard/Classes';
import PortalUser from '../components/dashboard/PortalUser';
import Settings from '../components/dashboard/Settings';
import TeacherPage from '../components/dashboard/Teachers';
import MerchantPage from '../components/dashboard/Merchant';
import StudentPage from '../components/dashboard/Student';
import ClassProfile from '../components/dashboard/ViewClass';
import GatePage from '../components/dashboard/Gate';
import ComplainPage from '../components/dashboard/Complain';
import CrowdfundingPage from '../components/dashboard/Crowdfunding';
import AnnouncementPage from '../components/dashboard/Announcement';
import CrowdfundingDetailPage from '../components/dashboard/CrowdfundingDetail';

export const routes = [
    { path: `/dashboard`, Component: <Homepage /> },
    { path: `/classes`, Component: <ClassesPage/>},
    { path: `/crowdfunding`, Component: <CrowdfundingPage/>},
    { path: `/crowdfunding-details/:id`,Component:<CrowdfundingDetailPage/>},
    { path: `/gate`, Component: <GatePage/>},
    { path: `/class-details/:id`,Component: <ClassProfile/>},
    { path: `/student`, Component: <StudentPage/>},
    { path: `/merchant`, Component: <MerchantPage/>},
    { path: `/teacher`, Component: <TeacherPage/>},
    { path: `/portalUser`, Component: <PortalUser /> },
    { path: `/settings`, Component: <Settings /> },
    { path: `/announcement`, Component: <AnnouncementPage/>},
    { path: `/feedback`, Component: <ComplainPage/>}
]
