import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const ClassesPage = () => {
    const [classes, setClasses] = useState([]);
    const [teachers, setTeachers] = useState([]); // List of all teachers
    const [selectedClass, setSelectedClass] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [updatedClassName, setUpdatedClassName] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedTeachers, setSelectedTeachers] = useState([]); // Selected teachers for the class
    const [newClassName, setNewClassName] = useState('');
    const [selectedForm, setSelectedForm] = useState(null); // Selected form for the new class
    
    const navigate = useNavigate(); 
    const forms = Array.from({ length: 6 }, (_, index) => ({
        label: `Form/Standard/Tadika ${index + 1}`,
        value: `${index + 1}`,
    }));
    const [columns] = useState([
        {
            name: "Class Name",
            selector: (row) => row.class_name,
            sortable: true,
            center: true,
        },
        {
            name: "Teachers",
            selector: (row) => (
                <div>
                    {row.teachers.length > 0 ? (
                        row.teachers.map((teacher, index) => (
                            <div key={index}>
                                {teacher.teacher_name}
                            </div>
                        ))
                    ) : (
                        <div>No Teachers Assigned</div>
                    )}
                </div>
            ),
            center: true,
        },
        {
            name: "",
            cell: (row) => (
                <div className=''>
                    <button
                    className="btn btn-dark"
                    style={{ marginRight: '10px' }} // Add custom spacing
                    onClick={() => navigate(`/class-details/${row.class_id}`)}
                >
                    View
                </button>
                <button
                    className="btn btn-info"
                    style={{ marginRight: '10px' }} // Add custom spacing
                    onClick={() => openEditModal(row)}
                >
                    Edit
                </button>
                <button
                    className="btn btn-danger"
                    onClick={() => openDeleteModal(row)}
                >
                    Delete
                </button>
            </div>
            
            ),
            center: true,
        }
    ]);

    // Fetch classes from the API
    const fetchClasses = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get('/portal/getClasses', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    setClasses(response.data.data);
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error fetching classes", { autoClose: 5000 });
            });
    };

    // Fetch all teachers from the API
    const fetchTeachers = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get('/portal/getTeachers', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {

                    const teacherOptions = response.data.data.map((teacher) => ({
                        label: teacher.full_name,
                        value: teacher.teacher_uuid,
                    }));

                    setTeachers(teacherOptions);
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error fetching teachers", { autoClose: 5000 });
            });
    };

    useEffect(() => {
        fetchClasses();
        fetchTeachers();
    }, []);

    // Open the modal for editing a class
    const openEditModal = (classData) => {
        setSelectedClass(classData);
        setUpdatedClassName(classData.class_name);
        setSelectedTeachers(classData.teachers.map((teacher) => ({
            label: teacher.teacher_name,
            value: teacher.teacher_id,
        })));
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedClass(null);
        setIsEditModalOpen(false);
    };
    const openAddModal = () => {
        setNewClassName('');
        setSelectedTeachers([]);
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };
    const openDeleteModal = (classData) => {
        setSelectedClass(classData);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedClass(null);
        setIsDeleteModalOpen(false);
    };
    const handleAddClass = () => {
        if (!newClassName) {
            toast.error("Class name cannot be empty", { autoClose: 5000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.post('/portal/addClass', {
            class_name: newClassName,
            form: selectedForm.value,
        }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    toast.success("Class added successfully", { autoClose: 5000 });
                    closeAddModal();
                    fetchClasses();
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error adding class", { autoClose: 5000 });
            });
    };
    const handleDeleteClass = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.post('/portal/deleteClass', {
            class_id: selectedClass.class_id,
        }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    toast.success("Class deleted successfully", { autoClose: 5000 });
                    closeDeleteModal();
                    fetchClasses();
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error deleting class", { autoClose: 5000 });
            });
    };
    const handleSaveChanges = () => {
        if (!updatedClassName) {
            toast.error("Class name cannot be empty", { autoClose: 5000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        // Map selected teachers to their UUIDs
        const teacherUUIDs = selectedTeachers.map((teacher) => teacher.value);
        // Send the updated list of teachers to the backend
        API.post('/portal/updateTeachersForClass', {
            class_id: selectedClass.class_id,
            teacher_uuids: teacherUUIDs,
        }, { headers })
            .then((response) => {
                console.log(response)
                if (response.data.response_code === 1000) {
                    toast.success("Class updated successfully", { autoClose: 5000 });
                    closeEditModal();
                    fetchClasses(); // Refresh the classes list
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error updating class", { autoClose: 5000 });
            });
    };


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25 text-red">
                                <h5>Classes</h5>
                                <button
                                    className="btn btn-primary float-end"
                                    onClick={openAddModal}
                                >
                                    Add Class
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={classes}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedClass && (
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
                    <ModalHeader toggle={closeEditModal}>
                        Edit Class Details
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>Class Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={updatedClassName}
                                onChange={(e) => setUpdatedClassName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Assign Teachers</label>
                            {isEditModalOpen && (
                                <Select
                                    isMulti
                                    options={teachers}
                                    value={selectedTeachers}
                                    onChange={setSelectedTeachers}
                                />
                            )}

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            <Modal isOpen={isAddModalOpen} toggle={closeAddModal}>
                <ModalHeader toggle={closeAddModal}>
                    Add New Class
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>Class Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={newClassName}
                            onChange={(e) => setNewClassName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Select Standard/Form</label>
                        <Select
                            options={forms}
                            value={selectedForm}
                            onChange={setSelectedForm}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="btn btn-dark" onClick={handleAddClass}>
                        Add Class
                    </Button>
                    <Button color="btn btn-primary" onClick={closeAddModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
                <ModalHeader toggle={closeDeleteModal}>
                    Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the class: <strong>{selectedClass?.class_name}</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button color="btn btn-dark" onClick={handleDeleteClass}>
                        Yes, Delete
                    </Button>
                    <Button color="btn btn-primary" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default ClassesPage;
