import React, { useState, useEffect, Fragment } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import API from "../../services/API";
import { toast } from "react-toastify";
import moment from 'moment';
import { FaGripLines } from "react-icons/fa"; // Import drag icon

const ComplaintItem = ({ complaint, moveComplaint, addComment }) => {
    const [comment, setComment] = useState("");
    const [showReplies, setShowReplies] = useState(false);

    const [{ isDragging }, drag] = useDrag({
        type: "COMPLAINT",
        item: { id: complaint.complaint_id, status: complaint.status },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    return (
        <div ref={drag} className="complaint-item card p-2 mb-2 shadow-sm"
            style={{ opacity: isDragging ? 0.5 : 1, cursor: "grab" }}>

            {/* Drag Icon */}
            <FaGripLines style={{ fontSize: "18px", marginRight: "10px", cursor: "grab", color: "#888" }} />

            <h4 className="text-muted font-weight-bold mt-1">{complaint.description}</h4>
            <p className="text-secondary" style={{ fontSize: "13px" }}>
                <strong>ID:</strong> {complaint.complaint_id} <br />
                <strong>School:</strong> {complaint.school_name} <br />
                <strong>Created:</strong> {moment(complaint.created_at).format("MMM D, YYYY h:mm A")}
            </p>

            {/* If there are comments, hide input inside the dropdown */}
            {complaint.replies?.length > 0 ? (
                <div className="mt-2 p-2 bg-light rounded">
                    <button className="btn btn-sm btn-primary mb-2 w-100" onClick={() => setShowReplies(!showReplies)}>
                        {showReplies ? "Hide Comments" : `View Comments (${complaint.replies.length})`}
                    </button>

                    {showReplies && (
                        <>
                            <ul className="p-0" style={{ listStyle: "none" }}>
                                {complaint.replies.map((reply, index) => (
                                    <li key={index} className="mb-1 d-flex flex-column"
                                        style={{ alignItems: reply.user_role === "admin" ? "flex-start" : "flex-end" }}>

                                        <div className="p-2 rounded"
                                            style={{
                                                backgroundColor: reply.user_role === "admin" ? "#e6f7ff" : "#f0f0f0",
                                                maxWidth: "100%",
                                                fontSize: "13px"
                                            }}>
                                            <strong className={reply.user_role === "admin" ? "text-primary" : "text-dark"}>
                                                {reply.first_name} ({reply.user_role === "admin" ? "Admin" : "User"})
                                            </strong>
                                            <p className="m-0 text-black p-2">{reply.comment}</p>
                                            <small className="text-muted">{moment(reply.created_at).format("MMM D, YYYY h:mm A")}</small>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            {/* Input field inside dropdown when there are comments */}
                            <div className="mt-1">
                                <input type="text" className="form-control form-control-sm"
                                    placeholder="Add a comment..." value={comment}
                                    onChange={(e) => setComment(e.target.value)} />
                                <button className="btn btn-sm btn-primary mt-1 w-100"
                                    onClick={() => { addComment(complaint.complaint_id, comment); setComment(""); }}>
                                    Reply
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                // If no comments, show input field directly
                <div className="mt-2 p-2 bg-light rounded">
                    <input type="text" className="form-control form-control-sm"
                        placeholder="Add a comment..." value={comment}
                        onChange={(e) => setComment(e.target.value)} />
                    <button className="btn btn-sm btn-primary mt-1 w-100"
                        onClick={() => { addComment(complaint.complaint_id, comment); setComment(""); }}>
                        Reply
                    </button>
                </div>
            )}
        </div>
    );
};



const ComplaintLane = ({ title, status, complaints, moveComplaint, addComment }) => {
    const [{ isOver }, drop] = useDrop({
        accept: "COMPLAINT",
        drop: (item) => moveComplaint(item.id, status),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} className="col-md-4">
            <div className={`card shadow-lg ${isOver ? "border-primary border-2" : ""}`} style={{ borderRadius: "10px", overflow: "hidden" }}>
                <div className={`font-weight-bold card-header text-white text-center fw-bold 
                    // ${status === "pending" ? "bg-primary" : status === "in_progress" ? "bg-warning" : "bg-success"}`}>
                    <h5>{title}</h5>
                </div>

                <div className="card-body" style={{ minHeight: "250px", backgroundColor: "#f8f9fa", transition: "background 0.3s ease-in-out" }}>
                    {complaints.length === 0 ? (
                        <p className="text-muted text-center mt-3" style={{ fontSize: "14px" }}>
                            No feedback here.
                        </p>
                    ) : (
                        complaints.map((complaint) => (
                            <ComplaintItem key={complaint.complaint_id} complaint={complaint} moveComplaint={moveComplaint} addComment={addComment} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};


const ComplainPage = () => {
    const [complaints, setComplaints] = useState({
        pending: [],
        inProgress: [],
        resolved: [],
    });

    useEffect(() => {
        fetchComplaints();
    }, []);

    const fetchComplaints = async () => {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "token": localStorage.getItem("token"),
        };

        try {
            const response = await API.post("/complaint/getComplaintsAsAdmin", {}, { headers });

            if (response.data.response_code === 1000) {
                const allComplaints = response.data.complaints;

                // Fetch replies for each complaint
                const complaintsWithReplies = await Promise.all(
                    allComplaints.map(async (complaint) => {
                        const replyRes = await API.post("/complaint/getComplaintReplies", { complaint_id: complaint.complaint_id }, { headers });

                        if (replyRes.data.response_code === 1000) {
                            return { ...complaint, replies: replyRes.data.replies };
                        }
                        return { ...complaint, replies: [] };
                    })
                );

                const pending = complaintsWithReplies.filter((c) => c.status.toLowerCase() === "pending");
                const inProgress = complaintsWithReplies.filter((c) => c.status.toLowerCase() === "in_progress");
                const resolved = complaintsWithReplies.filter((c) => c.status.toLowerCase() === "resolved");

                setComplaints({ pending, inProgress, resolved });
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error("Error fetching complaints", { autoClose: 5000 });
        }
    };

    const moveComplaint = (id, newStatus) => {
        setComplaints((prevState) => {
            const updatedComplaints = {
                pending: [...prevState.pending],
                inProgress: [...prevState.inProgress],
                resolved: [...prevState.resolved]
            };

            let movedComplaint = null;
            for (let key in updatedComplaints) {
                const index = updatedComplaints[key].findIndex((c) => c.complaint_id === id);
                if (index !== -1) {
                    movedComplaint = updatedComplaints[key].splice(index, 1)[0];
                    break;
                }
            }

            if (movedComplaint) {
                movedComplaint.status = newStatus;
                if (newStatus === "pending") updatedComplaints.pending.push(movedComplaint);
                if (newStatus === "in_progress") updatedComplaints.inProgress.push(movedComplaint);
                if (newStatus === "resolved") updatedComplaints.resolved.push(movedComplaint);
            }

            return updatedComplaints;
        });

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "token": localStorage.getItem("token"),
        };
        API.post("/complaint/updateComplaintStatusAsAdmin", { complaint_id: id, status: newStatus }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    toast.success("Complaint status updated successfully");
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error updating feedback id status", { autoClose: 5000 });
            });
    };

    const addComment = (complaint_id, comment) => {
        if (!comment) return;

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "token": localStorage.getItem("token"),
        };

        API.post("/complaint/adminReplyComplaint", { complaint_id, comment }, { headers })
            .then((response) => {
                console.log(response)
                if (response.data.response_code === 1000) {
                    toast.success("Comment added successfully");
                    fetchComplaints(); // Refresh complaints to show new comments
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error adding comment", { autoClose: 5000 });
            });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <ComplaintLane title="Pending" status="pending" complaints={complaints.pending} moveComplaint={moveComplaint} addComment={addComment} />
                        <ComplaintLane title="In Progress" status="in_progress" complaints={complaints.inProgress} moveComplaint={moveComplaint} addComment={addComment} />
                        <ComplaintLane title="Resolved" status="resolved" complaints={complaints.resolved} moveComplaint={moveComplaint} addComment={addComment} />
                    </div>
                </div>
            </Fragment>
        </DndProvider>
    );
};

export default ComplainPage;
