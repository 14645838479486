import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const AnnouncementPage = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [schools, setSchools] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [newAnnouncementTitle, setNewAnnouncementTitle] = useState('');
    const [newAnnouncementBody, setNewAnnouncementBody] = useState('');
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [selectedSchool, setSelectedSchool] = useState([]); // Selected teachers for the class
    const [schoolMap, setSchoolMap] = useState({}); // Store school_id -> school_name mapping

    // Columns for the DataTable
    const [columns] = useState([
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: true,
            center: true,
        },
        {
            name: "Body",
            selector: (row) => row.message,
            center: true,
        },
        {
            name: "Schools Sent To",
            selector: (row) => row.schools.map(school => school.name).join(", "), // Show school names instead of IDs
            wrap: true, // Allow long text to wrap
            center: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div>
                    <button
                        className="btn btn-danger"
                        onClick={() => openDeleteModal(row)}
                    >
                        Delete
                    </button>
                </div>
            ),
            center: true,
        },
    ]);

    const fetchAnnouncements = () => {
        if (isLoading) return;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get('/portal/obtainBulletinAdmin', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    const uniqueAnnouncementsMap = new Map();

                    response.data.data.forEach((announcement) => {
                        const uniqueKey = `${announcement.title}_${announcement.message}`;

                        if (!uniqueAnnouncementsMap.has(uniqueKey)) {
                            uniqueAnnouncementsMap.set(uniqueKey, {
                                ...announcement,
                                schools: [{
                                    id: announcement.school_id,
                                    name: schoolMap[announcement.school_id] || "Unknown School" // Map school_id to school_name
                                }],
                            });
                        } else {
                            // Append school ID and Name to the existing entry (Avoid duplicates)
                            const existingSchools = uniqueAnnouncementsMap.get(uniqueKey).schools;
                            if (!existingSchools.some(s => s.id === announcement.school_id)) {
                                existingSchools.push({
                                    id: announcement.school_id,
                                    name: schoolMap[announcement.school_id] || "Unknown School"
                                });
                            }
                        }
                    });

                    // Convert the Map values to an array
                    const uniqueAnnouncements = Array.from(uniqueAnnouncementsMap.values());

                    setAnnouncements(uniqueAnnouncements);
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error fetching announcements", { autoClose: 5000 });
            });
    };


    useEffect(() => {
        getSchools();
    }, []);
    
    useEffect(() => {
        fetchAnnouncements();
    }, [isLoading]); // Trigger announcement fetch after schools are loaded

    const openAddModal = () => {
        setNewAnnouncementTitle('');
        setNewAnnouncementBody('');
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const openDeleteModal = (announcement) => {
        setSelectedAnnouncement(announcement);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedAnnouncement(null);
        setIsDeleteModalOpen(false);
    };

    const handleAddAnnouncement = () => {
        if (!newAnnouncementTitle || !newAnnouncementBody) {
            toast.error("Title and body cannot be empty", { autoClose: 5000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const selectedSchoolIds = selectedSchool.map(school => school.value);
        API.post('/portal/sendBulletin', {
            title: newAnnouncementTitle,
            body: newAnnouncementBody,
            schools: selectedSchoolIds
        }, { headers })
            .then((response) => {

                if (response.status == 200) {
                    toast.success("Announcement added successfully", { autoClose: 5000 });
                    closeAddModal();
                    fetchAnnouncements();
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error adding announcement", { autoClose: 5000 });
            });
    };

    const handleDeleteAnnouncement = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.post('/portal/deleteBulletin', { bulletin_id: selectedAnnouncement.bulletin_id }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    toast.success("Announcement deleted successfully", { autoClose: 5000 });
                    closeDeleteModal();
                    fetchAnnouncements();
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error deleting announcement", { autoClose: 5000 });
            });
    };
    const getSchools = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get('/portal/getClasses', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    const formattedSchools = response.data.data.map(school => ({
                        label: school.class_name, // Replace with correct field name
                        value: school.school_id,   // Replace with correct field name
                    }));
                    // Add "All Schools" selection
                    const allSchoolsOption = { label: "All Schools", value: "all" };
                    const schoolMapping = {};
                    if (response.data.data[0].school_type == 'tadika') {
                        response.data.data.forEach(school => {
                            schoolMapping[school.school_id] = school.class_name;
                        });
                        setSchools([allSchoolsOption, ...formattedSchools]);
                    }
                    else {
                        setSchools([allSchoolsOption]);
                    }
                    setSchoolMap(schoolMapping);
                    setIsLoading(false); 
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch((error) => {
                console.error("Error fetching classes:", error); // Debugging
                toast.error("Error fetching classes", { autoClose: 5000 });
            });
    };
    const handleSelectChange = (selectedOptions) => {
        // If "All Schools" is selected, set all schools as selected
        if (selectedOptions.some(option => option.value === "all")) {
            setSelectedSchool(schools); // Select all schools
        } else {
            setSelectedSchool(selectedOptions);
        }
    };


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25 text-red">
                                <h5>Announcements</h5>
                                <button
                                    className="btn btn-primary float-end"
                                    onClick={openAddModal}
                                >
                                    Add Announcement
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={announcements}
                                        striped
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Announcement Modal */}
            <Modal isOpen={isAddModalOpen} toggle={closeAddModal}>
                <ModalHeader toggle={closeAddModal}>
                    Add New Announcement
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>Title</label>
                        <input
                            type="text"
                            className="form-control"
                            value={newAnnouncementTitle}
                            onChange={(e) => setNewAnnouncementTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Body</label>
                        <textarea
                            className="form-control"
                            rows="5"
                            value={newAnnouncementBody}
                            onChange={(e) => setNewAnnouncementBody(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Send to SCHOOL</label>
                        <Select
                            isMulti
                            options={schools}
                            value={selectedSchool}
                            onChange={handleSelectChange}
                            closeMenuOnSelect={false}
                        />

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddAnnouncement}>
                        Add
                    </Button>
                    <Button color="secondary" onClick={closeAddModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Delete Announcement Modal */}
            <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
                <ModalHeader toggle={closeDeleteModal}>
                    Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the announcement: <strong>{selectedAnnouncement?.title}</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDeleteAnnouncement}>
                        Yes, Delete
                    </Button>
                    <Button color="secondary" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default AnnouncementPage;
